<template>
  <div class="pd100">
    
    <div class="sliderPadding">

      <van-divider dashed>印花税占比</van-divider>
      <van-slider bar-height="4px" v-model="stampValue" active-color="#00cca2" @change="onChangeSlider">
        <template #button>
          <div class="custom-button">{{ stampValue }}%</div>
        </template>
      </van-slider>

      <van-divider dashed>增值税占比</van-divider>
      <van-slider bar-height="4px" v-model="addedValue" active-color="#00cca2" @change="onChangeSlider">
        <template #button>
          <div class="custom-button">{{ addedValue }}%</div>
        </template>
      </van-slider>

      <van-divider dashed>企税占比</van-divider>
      <van-slider bar-height="4px" v-model="comValue" active-color="#00cca2" @change="onChangeSlider">
        <template #button>
          <div class="custom-button">{{ comValue }}%</div>
        </template>
      </van-slider>

      <van-divider dashed>个税占比</van-divider>
      <van-slider bar-height="4px" v-model="personValue" active-color="#00cca2" @change="onChangeSlider">
        <template #button>
          <div class="custom-button">{{ personValue }}%</div>
        </template>
      </van-slider>
  </div>

    <ve-table
        v-if="!loading"
        :columns="columns" 
        :table-data="tableData"
        style="width:100%" 
        :scroll-width="400"
      />

    <div 
        class="float-ball"
        ref="ball"
        @touchstart.stop='handleTouchstart'
        @touchmove.stop='handleTouchmove'
        @touchend.stop='handleTouchend'
        @click.stop="showSheet"
        :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
        >
        <van-icon color="#00cca2" name="more-o" />
    </div>
  </div>
</template>
<script>

import {Toast, Tabbar, TabbarItem, Sidebar, SidebarItem, Search, Sticky, Button , NoticeBar, Slider, Divider} from 'vant'
import { debounce } from '@/common/util'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { weWorkchatConfig } from '@/common/wx'
// import BScroll from 'better-scroll'

export default {
  name: 'Mall',
  components: {
    [NoticeBar.name]:NoticeBar,
    [Button.name]:Button,
    [Sticky.name]:Sticky,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    [Slider.name]: Slider,
    [Divider.name]: Divider
  },
  data() {
    return {
      columns: [
          {field: 'month_name', key:'month_name', title: '月份', width: 80, fixed: "left",ellipsis: {showTitle: true},},
          {field: 'added', key:'added', title: '增值税', width: 110, },
          {field: 'stamp', key:'stamp', title: '印花税', width: 110, },
          {field: 'com_income', key:'com_income', title: '企税', width: 110, },
          {field: 'person_income', key:'person_income', title: '个税', width:110, },
      ],
      scroll: null,
      receive:false,
      receive_list:[],
      room_num:'',
      cart_count:'',
      pageTitle: '商城',
      keyword: '',
      categoryList: [],
      categoryActive: 0,
      total_count:0,
      value: 50,
      comValue: 10,
      stampValue: 70,
      addedValue: 10,
      personValue: 10,
      list: [],
      loading:false,
      is_search:false,
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      activeBar:3,
      can_del:0,
      total_money:0,
      total_fund:0,
      searchLoading:false,
      timer:'',
      isShowWeekView:true,
      isShowWeekViews:true,
      markDate:[],
      moveTop:415,
      moveLeft:317,
      tableData: []
    }
  },
  watch: {
    keyword(val) {
      this.search()
    }
  },
  computed: {

  },
  created() {
    if(this.$route.query.room_num){
      this.room_num=this.$route.query.room_num
    }
    if(this.room_num){
      this.$api.cai_cart({room_num:this.room_num}).then(res => {
        this.cart_count=res.data.cart_count>0?res.data.cart_count:''
      })
    }
    this.getOrder(0)
  },
  mounted() {
      this.getTax();
  },
  beforeDestroy() {
  },
  methods: {
    clickiNotice(){
    },
    onChangeSlider(){
      this.getTax();
    },
    getTax(){
      let that = this;
      this.$api.tax_index({comValue:this.comValue,stampValue:this.stampValue,addedValue:this.addedValue,personValue:this.personValue}).then(res => {
          let tableData = res.data.tax_list
          let total = 0
          let course_count = 0
          let course_revenue_count = 0
          let course_student_count = 0
          let course_times_count = 0
          that.tableData = tableData
      })
    },
    showSheet(){
      this.$api.cai_receive().then(res => {
          this.receive=true;
          this.receive_list= res.data.list;
      })
    },
    getOrder(index,order_date){
      const params = {
        // 'per-page': this.pageSize,
        keyword: this.keyword,
        order_date: order_date
      }
      this.$api.cai_order(params).then(res => {
        this.categoryList = res.data
        this.can_del = res.can_del
        this.total_count = res.total_count;
        this.total_fund=res.total_fund;
        this.total_money=res.total_price;
        // console.log(res.data)
        // this.queryProductPage()

      if(this.room_num){
          this.categoryList.forEach((item,zindex)=>{
              if(item.room_num==this.room_num){
                index = zindex
              }
          })
      }

        this.onCategory(index)
      })
    },
    totalClick(){
      Toast.fail('出单后会发起群收款。')
    },
    getCart(){
      this.$api.cai_cart({room_num:this.room_num}).then(res => {
        this.cart_count=res.data.cart_count>0?res.data.cart_count:''
      })
    },
    copySkuID(item,skuId){
        // console.log(skuId);
        this.$copyText(item.spuName +' '+skuId).then(function (e) {
            Toast.success('复制成功')
        }, function (e) {
            Toast.success('复制出错')
        })
    },
    scrollMe() {
      const el = this.$refs.wrapper
      const scrollTop = el.scrollTop
      const scrollHeight = el.scrollHeight
      const clientHeight = el.clientHeight
      const offset = 50 // 阀值
      if (scrollHeight - scrollTop - clientHeight < offset && !this.listLoading && !this.listFinished) {
        console.log('加载更多')
        this.pageCurrent += 1
        // this.queryProductPage()
      }
    },
    search: debounce(function() {
      const el = document.querySelector('.category-container')
      if (el) {
        el.scrollTo(0, 0)
      }
      this.pageCurrent = 1
      this.listLoading = true
      this.listFinished = false
      this.getOrder(0)
      // this.queryProductPage()
    }, 300),
    delCart(item){
      const params = {
        'order_id':item.id
      }
      this.$api.cai_del_cart(params).then(res => {
        Toast.success('删除成功');
        this.getOrder(this.categoryActive)
        this.getCart();
      })
    },
    handleTouchstart(e){
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    handleTouchmove(e){
        e.preventDefault()
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    handleTouchend(e){
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    onCategory(index) {
      // console.log(index)
      const el = document.querySelector('.category-container')
      if (el) {
        el.scrollTo(0, 0)
      }
      this.pageCurrent = 1
      this.listLoading = true
      this.listFinished = false
      this.categoryActive = index
      if(this.categoryList[index]){
        this.list = this.categoryList[index]['room_order']
      }else{
        this.list=[]
      }
      // console.log(this.categoryList[index])
      // this.queryProductPage()
    },
     handleClick(e){
        console.log(e)
        this.getOrder(0,e)
          // this.getList();
          // this.getList(e)
      },
      handleChange(e){
        console.log(e)
          // this.active = e
          // this.getList(e)
      },
      handleSlidechange(e){
          if (e=='up' || e=='down') return false
          let that = this
          setTimeout(()=>{
              that.getList()
          },0)
      },
      isShowWeeks(){
          this.isShowWeekViews = !this.isShowWeekViews
      },
    // delCart(item){
    //   console.log(item);
    //   this.delCart(item)
    // },
    onProductClick(item) {
      // console.log(item);
      wx.previewImage({
          current: item.photo, // 第一张显示的图片链接
          urls: [item.photo] // 需要预加载的图片http链接列表，预加载后，可以滑动浏览这些图片
      });
    }
  }
}
</script>
<style lang="less" scoped>
  .sliderPadding{
    padding: 0 10px 30px;
  }
  .custom-button {
    width: 36px;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    background-color: #00cca2;
    border-radius: 100px;
  }

  .finished-text{
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
  }
  .sidebar-solt-title{
    width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
</style>
<style lang="less">
    .schedule-calendar .van-cell__left-icon{
        color: #00cca2   
    }
  .schedule-calendar{
    .calendar_dot{
      width: 4PX;
      height: 4PX;
    }
    .calendar_content{
      overflow: initial;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
    }
    .calendar_first_today{
      color:#00cca2;
    }
  }
  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }
  .float-ball{
      position: fixed;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 2px #999;
      .van-icon{
          font-size: 0.8rem;
      }
  }
</style>